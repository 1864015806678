<script setup>
import { nextTick, onMounted } from 'vue';
import DropdownMenu from 'v-dropdown-menu';
import 'v-dropdown-menu/css';

const _TRANSLATION = window._TRANSLATION;
const _LANG = window._LANG;
const _LANG_URLS = window._LANG_URLS;
const _BASE_LANG_URL = window._BASE_LANG_URL;

nextTick(() => {
    /** Set selected lang */
    document.querySelector('.selected-lang__value').innerHTML = document.querySelector(`[data-lang="${_LANG}"]`).innerHTML;
});

</script>

<template>
    <footer>
        <div class="copyright">{{ _TRANSLATION.footer.copyright }}</div>

        <ul class="ul-clear footer-links">
            <li>
                <a :href="`${_BASE_LANG_URL}/terms`">{{ _TRANSLATION.footer.terms }}</a>
            </li>
            <li>
                <a :href="`${_BASE_LANG_URL}/privacy`">{{ _TRANSLATION.footer.privacy }}</a>
            </li>
            <li>
                <a href="#">{{ _TRANSLATION.footer.support }}</a>
            </li>
        </ul>

        <!-- <dropdown-menu :overlay="false" :dropup="true"> -->
        <dropdown-menu class="dropdown dropdown_langs dropdown_footer dropdown_footer_quest" :overlay="false" :direction="left" :dropup="true">
            <template #trigger>
                <div class="selected-lang">
                    <div class="selected-lang__value">
                        <span>UA</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="19" viewBox="0 0 26 19" fill="none">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V19H0V0Z" fill="#FFD700" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V9.5H0V0Z" fill="#0057B8" />
                        </svg>
                    </div>
                    <div class="dropdown-arrow">
                    </div>
                </div>
            </template>

            <template #body>
                <ul>
                    <li>
                        <a :href="_LANG_URLS.en" class="link-clear" data-lang="en">
                            <span>EN</span>
                            <img src="../../../images/en.svg" alt="">
                        </a>
                    </li>
                    <li>
                        <a :href="_LANG_URLS.es" class="link-clear" data-lang="es">
                            <span>ES</span>
                            <img src="../../../images/es.svg" alt="">
                        </a>
                    </li>
                    <li>
                        <a :href="_LANG_URLS.de" class="link-clear" data-lang="de">
                            <span>DE</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="19" viewBox="0 0 26 19" fill="none">
                                <g clip-path="url(#clip0_326_3581)">
                                    <path d="M0 12.6666H26V19H0V12.6666Z" fill="#FFCE00" />
                                    <path d="M0 0H26V6.33333H0V0Z" fill="black" />
                                    <path d="M0 6.33337H26V12.6667H0V6.33337Z" fill="#DD0000" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_326_3581">
                                        <rect width="26" height="19" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a :href="_LANG_URLS.fr" class="link-clear" data-lang="fr">
                            <span>FR</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="20" viewBox="0 0 26 20" fill="none">
                                <g clip-path="url(#clip0_326_3587)">
                                    <mask id="mask0_326_3587" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0"
                                        y="0" width="26" height="20">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V20H0V0Z" fill="white" />
                                    </mask>
                                    <g mask="url(#mask0_326_3587)">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.875 0H26V20H17.875V0Z"
                                            fill="#F50100" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H9.75V20H0V0Z"
                                            fill="#2E42A5" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.125 0H17.875V20H8.125V0Z"
                                            fill="#F7FCFF" />
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_326_3587">
                                        <rect width="26" height="20" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a :href="_LANG_URLS.ua" class="link-clear" data-lang="ua">
                            <span>UA</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="19" viewBox="0 0 26 19" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V19H0V0Z" fill="#FFD700" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V9.5H0V0Z" fill="#0057B8" />
                            </svg>
                        </a>
                    </li>
                    <li>
                        <a :href="_LANG_URLS.ru" class="link-clear" data-lang="ru">
                            <span>RU</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="19" viewBox="0 0 26 19" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V19H0V0Z" fill="white" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.33337H26V19H0V6.33337Z"
                                    fill="#0039A6" />
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12.6666H26V19H0V12.6666Z"
                                    fill="#D52B1E" />
                            </svg>
                        </a>
                    </li>
                </ul>
            </template>

        </dropdown-menu>
    </footer>
</template>
