<script setup>
import { ref } from 'vue';

import Leftbar from '../Leftbar.vue';
import Header from '../Header.vue';
import Banner from '../Banner.vue';
import NotFound from './NotFound.vue';

import ColorTable from './ColorsList/ColorTable.vue';
import ColorMatrix from './ColorsList/ColorMatrix.vue';
import ColorMatrix2x2 from './ColorsList/ColorMatrix2x2.vue';
import CodeTable from './CodesList/CodeTable.vue';
import CodeMatrix from './CodesList/CodeMatrix.vue';
import TextFormatter from './TextFormatter/TextFormatter.vue';

const props = defineProps(['user']);

window.userStore.setUser(props.user);

const _TRANSLATION = window._TRANSLATION;

const width = ref(window.innerWidth);   // Window width
const colorsActiveTab = ref(2); // Active colors tab
const codesActiveTab = ref(width.value > 991 ? 1 : 2); // Active codes tab
const color = ref('');  // Color to Search
const code = ref('');  // Code to Search
const key = ref(0); // Colors Key to update component
const codeKey = ref(0); // Codes Key to update component

const availableColors = [
    'black', 'dark gray', 'gray', 'white', 'gold', 'minecoin gold', 'yellow', 'green', 'dark green', 'light purple', 'dark purple', 'blue', 'dark blue', 'dark red', 'red', 'aqua', 'dark aqua'
];
const filteredColors = ref(availableColors);

const availableCodes = ['underline', 'bold', 'italic', 'strikethrough', 'obfuscated', 'reset'];
const filteredCodes = ref(availableCodes);

const keyCodesToSkip = [13, 16, 17, 18, 33, 34, 35, 36, 37, 38, 39, 40, 144];   // Key codes to skip on key upi

onresize = (event) => {
    width.value = window.innerWidth;
};

// Search color
const searchColor = (skipSymbols = false, event = null) => {
    //if( color.value.trim() == '' ) return;

    /* if(skipSymbols && /[^a-zA-Z0-9а-яА-ЯёЁ]/.test(color.value) ){
        return;
    } */
    // Look for keycodes
    if(skipSymbols && event != null){
        if( keyCodesToSkip.includes(event.keyCode) ) return;
    }

    key.value++;

    let exists = false;
    let foundColors = [];
    availableColors.forEach( (value, index) => {
        if( value.toLowerCase().includes( color.value.toLowerCase() ) ){
            exists = true;
            foundColors.push(value);
        }
    });

    if( !exists ) {
        filteredColors.value = [];
    } else {
        filteredColors.value = foundColors;
    }
}

// Search code
const searchCode = (skipSymbols = false, event = null) => {
    if(skipSymbols && event != null){
        if( keyCodesToSkip.includes(event.keyCode) ) return;
    }

    codeKey.value++;

    let exists = false;
    let foundCodes = [];
    availableCodes.forEach( (value, index) => {
        if( value.toLowerCase().includes( code.value.toLowerCase() ) ){
            exists = true;
            foundCodes.push(value);
        }
    });

    if( !exists ) {
        filteredCodes.value = [];
    } else {
        filteredCodes.value = foundCodes;
    }
}

</script>

<template>
    <div class="wrapper">
        <Leftbar></Leftbar>
        <main class="main">
            <Header></Header>
            <!-- Main content -->
            <div class="main__content">
                <Banner :title="_TRANSLATION.color_code.title" :extra_class="'banner_tall'">
                    <template #subtitle>
                        <h2 class="h6-title">{{ _TRANSLATION.color_code.subtitle }}</h2>
                    </template>
                </Banner>

                <!-- Colors -->
                <div class="card no-padding card_color">
                    <p class="color-label" style="top: 3px;" v-html="_TRANSLATION.color_code.colors_title"></p>
                    <div class="card__content">
                        <div class="colors">
                            <h5>{{ _TRANSLATION.color_code.search_label }}</h5>
                            <div class="flex-group colors-search">
                                <input type="text" class="input search-value" name="color" :placeholder="_TRANSLATION.color_code.color_placeholder" v-model="color" @keyup="searchColor(true, $event)">
                                <button class="btn" style="width: 37px; height: 37px;" @click="searchColor">
                                    <span style="top: 0;"><img src="../../../../images/icon-search.png" alt="" class="ok-icon"></span>
                                </button>
                            </div>

                            <div class="colors__sortings">
                                <div class="sorting" :class="{'active': colorsActiveTab == 1}" @click="colorsActiveTab = 1">
                                    <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="1">
                                    <rect x="0.5" y="0.00537109" width="21" height="5" fill="currentColor"/>
                                    <rect x="0.5" y="7.00537" width="21" height="5" fill="currentColor"/>
                                    <rect x="0.5" y="14.0054" width="21" height="5" fill="currentColor"/>
                                    </g>
                                    </svg>
                                </div>
                                <div class="sorting" :class="{'active': colorsActiveTab == 2}" @click="colorsActiveTab = 2">
                                    <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="1">
                                        <path d="M3.5 3.5H10.5V8.5H3.5V3.5Z" fill="currentColor"/>
                                        <rect x="11.5" y="3.5" width="7" height="5" fill="currentColor"/>
                                        <rect x="19.5" y="3.5" width="7" height="5" fill="currentColor"/>
                                        <rect x="3.5" y="10.5" width="7" height="5" fill="currentColor"/>
                                        <rect x="11.5" y="10.5" width="7" height="5" fill="currentColor"/>
                                        <rect x="19.5" y="10.5" width="7" height="5" fill="currentColor"/>
                                        <rect x="3.5" y="17.5" width="7" height="5" fill="currentColor"/>
                                        <rect x="11.5" y="17.5" width="7" height="5" fill="currentColor"/>
                                        <rect x="19.5" y="17.5" width="7" height="5" fill="currentColor"/>
                                        </g>
                                    </svg>
                                </div>
                                <div class="sorting" :class="{'active': colorsActiveTab == 3}" @click="colorsActiveTab = 3">
                                    <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="1">
                                        <rect x="4.5" y="3.5" width="9" height="8" fill="currentColor"/>
                                        <rect x="16.5" y="3.5" width="9" height="8" fill="currentColor"/>
                                        <rect x="4.5" y="14.5" width="9" height="8" fill="currentColor"/>
                                        <rect x="16.5" y="14.5" width="9" height="8" fill="currentColor"/>
                                        </g>
                                    </svg>
                                </div>
                            </div>

                            <transition mode="out-in">
                                <div v-if="filteredColors.length == 0" style="margin-top: 20px;">
                                    <NotFound></NotFound>
                                </div>
                                <div v-else>
                                    <transition mode="out-in">
                                        <ColorTable :colors="filteredColors" v-if="colorsActiveTab == 1" :key="key"></ColorTable>
                                        <ColorMatrix :colors="filteredColors" v-else-if="colorsActiveTab == 2" :key="key+1"></ColorMatrix>
                                        <ColorMatrix2x2 :colors="filteredColors" v-else :key="key+2"></ColorMatrix2x2>
                                    </transition>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                <!-- Codes -->
                <div class="card no-padding card_color">
                    <h3 class="color-title">{{ _TRANSLATION.color_code.codes_title }}</h3>
                    <p class="color-label" v-html="_TRANSLATION.color_code.codes_label"></p>
                    <div class="card__content">
                        <div class="colors">
                            <h5>{{ _TRANSLATION.color_code.search_label }}</h5>
                            <div class="flex-group colors-search">
                                <input type="text" class="input search-value" name="code" :placeholder="_TRANSLATION.color_code.codes_placeholder" v-model="code" @keyup="searchCode(true, $event)">
                                <button class="btn" style="width: 37px; height: 37px;" @click="searchCode">
                                    <span style="top: 0;"><img src="../../../../images/icon-search.png" alt="" class="ok-icon"></span>
                                </button>
                            </div>

                            <div class="colors__sortings" style="width: 106px;">
                                <div class="sorting" :class="{'active': codesActiveTab == 1}" @click="codesActiveTab = 1">
                                    <svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g opacity="1">
                                    <rect x="0.5" y="0.00537109" width="21" height="5" fill="currentColor"/>
                                    <rect x="0.5" y="7.00537" width="21" height="5" fill="currentColor"/>
                                    <rect x="0.5" y="14.0054" width="21" height="5" fill="currentColor"/>
                                    </g>
                                    </svg>
                                </div>
                                <div class="sorting" :class="{'active': codesActiveTab == 2}" @click="codesActiveTab = 2">
                                    <svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g opacity="1">
                                        <path d="M3.5 3.5H10.5V8.5H3.5V3.5Z" fill="currentColor"/>
                                        <rect x="11.5" y="3.5" width="7" height="5" fill="currentColor"/>
                                        <rect x="19.5" y="3.5" width="7" height="5" fill="currentColor"/>
                                        <rect x="3.5" y="10.5" width="7" height="5" fill="currentColor"/>
                                        <rect x="11.5" y="10.5" width="7" height="5" fill="currentColor"/>
                                        <rect x="19.5" y="10.5" width="7" height="5" fill="currentColor"/>
                                        <rect x="3.5" y="17.5" width="7" height="5" fill="currentColor"/>
                                        <rect x="11.5" y="17.5" width="7" height="5" fill="currentColor"/>
                                        <rect x="19.5" y="17.5" width="7" height="5" fill="currentColor"/>
                                        </g>
                                    </svg>
                                </div>
                            </div>

                            <transition mode="out-in">
                                <div v-if="filteredCodes.length == 0" style="margin-top: 20px;">
                                    <NotFound></NotFound>
                                </div>
                                <div v-else>
                                    <transition mode="out-in">
                                        <CodeTable :codes="filteredCodes" v-if="codesActiveTab == 1" :key="codeKey"></CodeTable>
                                        <CodeMatrix :codes="filteredCodes" v-else :key="codeKey+1"></CodeMatrix>
                                    </transition>
                                </div>
                            </transition>
                        </div>
                    </div>
                </div>

                <!-- Text formatter -->
                <div class="card no-padding card_color">
                    <h3 class="color-title">{{ _TRANSLATION.color_code.formatter_title }}</h3>
                    <p class="color-label" v-html="_TRANSLATION.color_code.formatter_label"></p>
                    <div class="card__content">
                        <TextFormatter></TextFormatter>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
