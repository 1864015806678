<script setup>

import Leftbar from '../Leftbar.vue';
import Header from '../Header.vue';
import Banner from '../Banner.vue';

const props = defineProps(['user']);

window.userStore.setUser(props.user);

const _TRANSLATION = window._TRANSLATION;

</script>

<template>
    <div class="wrapper">
        <Leftbar></Leftbar>
        <main class="main">
            <Header></Header>
            <!-- Main content -->
            <div class="main__content">
                <Banner :title="_TRANSLATION.privacy.title" :extra_class="['banner_tall', 'banner_tall_padding']">
                    <template #subtitle>
                        <h6 class="h6-title" v-html="_TRANSLATION.privacy.subtitle"></h6>
                    </template>
                </Banner>

                <div class="card welcome" v-html="_TRANSLATION.privacy.welcome"></div>

                <!-- Privacy -->
                <div class="card info">
                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.privacy[1].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.privacy[1].text"></div>
                    </div>

                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.privacy[2].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.privacy[2].text"></div>
                    </div>

                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.privacy[3].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.privacy[3].text"></div>
                    </div>

                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.privacy[4].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.privacy[4].text"></div>
                    </div>

                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.privacy[5].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.privacy[5].text"></div>
                    </div>

                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.privacy[6].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.privacy[6].text"></div>
                    </div>

                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.privacy[7].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.privacy[7].text"></div>
                    </div>

                </div>
            </div>
        </main>
    </div>
</template>
