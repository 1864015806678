/* Validation module */
const validationPassed = { valid: true };
export default {
    // Validate Email
    isEmailValid: (email) => {

        if(email == '' || email.length == 0){
            return {
                valid: false,
                error: window._VALIDATION.email.required
            };
        }

        if(email.length < 3) {
            return {
                valid: false,
                error: window._VALIDATION.email.min
            };
        }

        if(email.length > 320) {
            return {
                valid: false,
                error: window._VALIDATION.email.max
            };
        }

        if( !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w)*$/.test(email) ){
            return {
                valid: false,
                error: window._VALIDATION.email.regex
            };
        }

        /* if( /[^\-a-zA-Z0-9_@\.]+/g.test(email) ){
            return {
                valid: false,
                error: '>>> Адрес электронной почты может содержать только буквы латинского алфавита (верхний и нижний регистр), цифры, точки, дефисы, символ подчеркивания и символ @'
            };
        } */

        if( !email.includes('@') || !email.includes('.')) {
            return {
                valid: false,
                error: window._VALIDATION.email.format
            };
        }

        return validationPassed;
    },

    // Validate Pass
    isPassValid: (pass, field = 'pass1') => {
        if(pass == '' || pass.length == 0){
            return {
                valid: false,
                error: window._VALIDATION[field].min
            };
        }

        if(pass.length < 4) {
            return {
                valid: false,
                error: window._VALIDATION[field].min
            };
        }

        if(pass.length > 32) {
            return {
                valid: false,
                error: window._VALIDATION[field].max
            };
        }

        if( /\s/g.test(pass) ){
            return {
                valid: false,
                error: window._VALIDATION[field].not_regex
            };
        }

        return validationPassed;
    },

    // Validate Login
    isLoginValid: (login) => {
        if(login == '' || login.length == 0){
            return {
                valid: false,
                error: window._VALIDATION.nickname.required
            };
        }

        if(login.length < 3) {
            return {
                valid: false,
                error: window._VALIDATION.nickname.min
            };
        }

        if(login.length > 16) {
            return {
                valid: false,
                error: window._VALIDATION.nickname.max
            };
        }

        if( /\s/g.test(login) ){
            return {
                valid: false,
                error: window._VALIDATION.nickname.no_whitespace
            };
        }

        if( /[^\-a-zA-Z0-9_]+/g.test(login) ){
            return {
                valid: false,
                error: window._VALIDATION.nickname.not_regex
            };
        }


        return validationPassed;
    },

    /** Set errors */
    setErrors: (errorsData, reactiveErrors) => {
        if( Array.isArray(errorsData) ){
            errorsData.forEach( (value, index)  => {
                let errorType = Object.keys(value[0])[0];
                reactiveErrors[errorType] = value[0][errorType];
            });
        } else {
            for (const [key, value] of Object.entries(errorsData)) {
                reactiveErrors[key] = value;
            }
        }


          /*
        errorsData.forEach( (value, index)  => {
            let errorType = Object.keys(value[0])[0];
            reactiveErrors[errorType] = value[0][errorType];
        }); */
    }
}
