<script setup>

import Leftbar from '../Leftbar.vue';
import Header from '../Header.vue';
import Banner from '../Banner.vue';

const props = defineProps(['user']);

window.userStore.setUser(props.user);

const _TRANSLATION = window._TRANSLATION;

</script>

<template>
    <div class="wrapper">
        <Leftbar></Leftbar>
        <main class="main">
            <Header></Header>
            <!-- Main content -->
            <div class="main__content">
                <Banner :title="_TRANSLATION.terms.title" :extra_class="'banner_tall'">
                    <template #subtitle>
                        <h6 class="h6-title" v-html="_TRANSLATION.terms.subtitle"></h6>
                    </template>
                </Banner>

                <!-- Terms -->
                <div class="card info">
                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.terms[1].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.terms[1].text"></div>
                    </div>
                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.terms[2].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.terms[2].text"></div>
                    </div>
                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.terms[3].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.terms[3].text"></div>
                    </div>
                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.terms[4].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.terms[4].text"></div>
                    </div>
                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.terms[5].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.terms[5].text"></div>
                    </div>
                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.terms[6].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.terms[6].text"></div>
                    </div>
                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.terms[7].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.terms[7].text"></div>
                    </div>
                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.terms[8].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.terms[8].text"></div>
                    </div>
                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.terms[9].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.terms[9].text"></div>
                    </div>
                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.terms[10].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.terms[10].text"></div>
                    </div>
                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.terms[11].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.terms[11].text"></div>
                    </div>
                    <div class="info-block">
                        <div class="info-block__header"><span>{{ _TRANSLATION.terms[12].title }}</span></div>
                        <div class="info-block__body" v-html="_TRANSLATION.terms[12].text"></div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</template>
