<script setup>
import { ref, reactive } from 'vue';

import validation from '../../validation';

import Footer from '../parts/Footer.vue';

const passHidden = ref(true);  // Is pass hidden?
const rememberMe = ref(false);   // Is remember me checked?
const hasErrors = ref(false);  // Are errors set?
const errors = reactive({});

const fatalErrors = ref('');    // Fatal errors (From back)
const submitButtonDisabled = ref(false); // Is submit button disabled?

const _TRANSLATION = window._TRANSLATION;
const _BASE_LANG_URL = window._BASE_LANG_URL;

// Auth
const auth = () => {
    submitButtonDisabled.value = true;

    fatalErrors.value = '';
    if( Object.keys(errors).length == 0 ) hasErrors.value = false;

    let email = document.getElementById('email').value;
    // После @ проблема с проверкой кириллицы

    let emailTest = validation.isEmailValid(email);
    if( !emailTest.valid ) {
        hasErrors.value = true;
        errors.email = emailTest.error;
    }

    let password = document.getElementById('pass1').value;

    let passwordTest = validation.isPassValid(password);
    if( !passwordTest.valid ) {
        hasErrors.value = true;
        errors.password = passwordTest.error;
    }

    if(hasErrors.value) {
        submitButtonDisabled.value = false;
        return; // If errors are present, stop here
    }

    // Login Action
    axios.post(`${_BASE_LANG_URL}/auth`, {
        email, pass1: password, remember: rememberMe.value
    })
    .then(function (response) {
        console.log(response);
        // Validation errors
        if(response.data.error != null){
            hasErrors.value = true;
            response.data.error.forEach( (value, index)  => {
                let errorType = Object.keys(value[0])[0];
                errors[errorType] = value[0][errorType];
            });
            submitButtonDisabled.value = false;
            return;
        }

        // Logical errors
        if(response.data.fatal_error != null){
            fatalErrors.value = response.data.fatal_error;
            submitButtonDisabled.value = false;
            return;
        }

        window.location.href = _BASE_LANG_URL;
    })
    .catch(function (error) {
        console.log(error);
        fatalErrors.value = error;
        submitButtonDisabled.value = false;
    });
};

// Validate
const validate = (callback, type) => {
    let result = callback;
    if(!result.valid) {
        hasErrors.value = true;
        errors[type] = result.error;
    }
};

</script>

<template>
    <transition appear>
        <div class="wrapper-small">
            <a :href="_BASE_LANG_URL" class="link-clear link-logo">
                <img src="../../../images/logo.png" class="img-adaptive logo" alt="Logo">
            </a>

            <div class="box">
                <h1 class="title">{{ _TRANSLATION.login.title }}</h1>

                <!-- <form class="form" id="reg-form" @keyup.enter="auth"> -->
                <form class="form" id="reg-form" @keyup.enter="auth">
                    <div class="input-group">
                        <label for="email">{{ _TRANSLATION.common.email }}</label>
                        <input type="email" class="input" :class="{'input_error': hasErrors && errors.email}" name="email" id="email" :placeholder="_TRANSLATION.common.email" @input="delete errors.email" :onFocusout="(event) => validate( validation.isEmailValid(event.target.value), 'email' )">
                        <transition>
                            <div class="input-group__error" v-if="hasErrors && errors.email">{{ errors.email }}</div>
                        </transition>
                    </div>

                    <div class="input-group">
                        <label for="pass1">{{ _TRANSLATION.common.pass }}</label>
                        <div class="input-custom input-custom_full" :class="{'input_error': hasErrors && errors.password}">
                            <input :type="passHidden ? 'password' : 'text'" class="input input_clear" name="pass1"
                                id="pass1" :placeholder="_TRANSLATION.common.pass" @input="delete errors.password" :onFocusout="(event) => validate( validation.isPassValid(event.target.value), 'password' )">
                            <div class="input-custom__manager" @click="passHidden = !passHidden">
                                <transition>
                                    <div class="crossed-line" v-if="passHidden"></div>
                                </transition>
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                    <path d="M8.33333 6.25H16.6667V8.33333H8.33333V6.25ZM4.16667 10.4167V8.33333H8.33333V10.4167H4.16667ZM2.08333 12.5V10.4167H4.16667V12.5H2.08333ZM2.08333 14.5833V12.5H0V14.5833H2.08333ZM4.16667 16.6667H2.08333V14.5833H4.16667V16.6667ZM8.33333 18.75H4.16667V16.6667H8.33333V18.75ZM16.6667 18.75V20.8333H8.33333V18.75H16.6667ZM20.8333 16.6667V18.75H16.6667V16.6667H20.8333ZM22.9167 14.5833V16.6667H20.8333V14.5833H22.9167ZM22.9167 12.5H25V14.5833H22.9167V12.5ZM20.8333 10.4167H22.9167V12.5H20.8333V10.4167ZM20.8333 10.4167V8.33333H16.6667V10.4167H20.8333ZM10.4167 11.4583H14.5833V15.625H10.4167V11.4583Z" fill="#8A8A8A"/>
                                </svg>
                            </div>
                        </div>
                        <transition>
                            <div class="input-group__error" v-if="hasErrors && errors.password">{{ errors.password }}</div>
                        </transition>
                    </div>

                    <div class="login-additional">
                        <div class="remember-me" @click="rememberMe = !rememberMe">
                            <div class="checker" :class="{ 'selected': rememberMe }"></div>
                            <span>{{ _TRANSLATION.login.remember_me }}</span>
                        </div>

                        <a :href="_BASE_LANG_URL + '/pass_reset'" class="link-clear"><p>{{ _TRANSLATION.login.forget_pass }}</p></a>
                    </div>

                    <div class="fatal-errors" v-if="fatalErrors.length > 0">
                        {{  fatalErrors  }}
                    </div>

                    <button class="btn" type="button" id="auth" :disabled="submitButtonDisabled" @click="auth"><span>{{ _TRANSLATION.login.auth }}</span></button>

                    <div class="text-center">
                        <a :href="_BASE_LANG_URL + '/register'" class="link-small">{{ _TRANSLATION.login.register }}</a>
                    </div>
                </form>
            </div>

            <!-- Footer Component -->
            <Footer></Footer>
        </div>
    </transition>
</template>
