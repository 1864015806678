<script setup>
import { onMounted } from 'vue';
import $ from 'jquery';

onMounted( () => {
    $('body').mouseup(function () {
        $('.output').height($('textarea').height());
        $('.output').height($('textarea').height());
    });

    $('.bgs div').click(function (elem) {
        $('.output').css('background', $(elem.target).css('background'));
        $('.bgs div').css('border', 0);
        $(elem.target).css('border', '3px #aaa solid');
    });


    let motd_raw = $('.editor textarea');
    $('.editor-tools button').click(function (e) {
        let caretPos = motd_raw[0].selectionStart;
        let textAreaTxt = motd_raw.val();
        let txtToAdd = '&' + $(this).attr('data-color');
        motd_raw.val(textAreaTxt.substring(0, caretPos) + txtToAdd + textAreaTxt.substring(caretPos)).focus();
        motd_raw.selectRange(caretPos + 2);
        colour(motd_raw.val());
    });

    setInterval(function () {
        $('.obfuscated').text(randomizer($('.obfuscated').text()));
    }, 100);
} );

$.fn.selectRange = function (start, end) {
    if (!end) end = start;
    return this.each(function () {
        if (this.setSelectionRange) {
            this.focus();
            this.setSelectionRange(start, end);
        } else if (this.createTextRange) {
            let range = this.createTextRange();
            range.collapse(true);
            range.moveEnd('character', end);
            range.moveStart('character', start);
            range.select();
        }
    });
};

function colour(text) {
    let left = htmlEncode("<");
    let right = htmlEncode(">");

   /*  if(element.target == null) return;

    let text = element.target.value; */

    text = text.replace(/</gi, left);
    text = text.replace(/>/gi, right);
    text = text.replace(/\n/gi, "&r<br />");
    //colours
    text = text.replace(/&0/gi, '</span>&r<span class="c-1">');
    text = text.replace(/&1/gi, '</span>&r<span class="c-2">');
    text = text.replace(/&2/gi, '</span>&r<span class="c-3">');
    text = text.replace(/&3/gi, '</span>&r<span class="c-4">');
    text = text.replace(/&4/gi, '</span>&r<span class="c-5">');
    text = text.replace(/&5/gi, '</span>&r<span class="c-6">');
    text = text.replace(/&6/gi, '</span>&r<span class="c-7">');
    text = text.replace(/&7/gi, '</span>&r<span class="c-8">');
    text = text.replace(/&8/gi, '</span>&r<span class="c-9">');
    text = text.replace(/&9/gi, '</span>&r<span class="c-10">');
    text = text.replace(/&a/gi, '</span>&r<span class="c-11">');
    text = text.replace(/&b/gi, '</span>&r<span class="c-12">');
    text = text.replace(/&c/gi, '</span>&r<span class="c-13">');
    text = text.replace(/&d/gi, '</span>&r<span class="c-14">');
    text = text.replace(/&e/gi, '</span>&r<span class="c-15">');
    text = text.replace(/&f/gi, '</span>&r<span class="c-16">');
    //bold
    text = text.replace(/&l/gi, "<span style='font-weight:900;'>");
    //italic
    text = text.replace(/&o/gi, "<span style='font-style:italic;'>");
    //strikethrough
    text = text.replace(/&m/gi, "<span style='text-decoration:line-through'>");
    //underlined
    text = text.replace(/&n/gi, "<span style='text-decoration:underline'>");
    //obfuscated
    text = text.replace(/&k/gi, "<span class='obfuscated'>");
    //reset
    text = text.replace(/&r/gi, "</span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span></span>");

    document.getElementById('output').innerHTML = text;
}

function htmlEncode(value) {
    return $('<div/>').text(value).html();
}

function randomizer(rawr) {
    let length = rawr.length;
    let text = '';
    let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (let i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}
</script>

<template>
    <div class="editor-container">
        <div class="editor-tools">
            <div class="editor-colors">
                <button data-color="0" class="bg-1">&amp;0</button>
                <button data-color="1" class="bg-2">&amp;1</button>
                <button data-color="2" class="bg-3">&amp;2</button>
                <button data-color="3" class="bg-4">&amp;3</button>
                <button data-color="4" class="bg-5">&amp;4</button>
                <button data-color="5" class="bg-6">&amp;5</button>
                <button data-color="6" class="bg-7">&amp;6</button>
                <button data-color="7" class="bg-8">&amp;7</button>
                <button data-color="8" class="bg-9">&amp;8</button>
                <button data-color="9" class="bg-10">&amp;9</button>
                <button data-color="a" class="bg-11">&amp;a</button>
                <button data-color="b" class="bg-12">&amp;b</button>
                <button data-color="c" class="bg-13">&amp;c</button>
                <button data-color="d" class="bg-14">&amp;d</button>
                <button data-color="e" class="bg-15">&amp;e</button>
                <button data-color="f" class="bg-16">&amp;f</button>
            </div>
            <div class="style">
                <button data-color="l"><span style="font-weight: bold;">Bold</span></button>
                <button data-color="n"><span style="text-decoration: underline;">Underline</span></button>
                <button data-color="o"><span style="font-style: italic;">Italic</span></button>
                <button data-color="m"><span style="text-decoration: line-through;">Strikethrough</span></button>
                <button data-color="k">Obfuscated</button>
                <button data-color="r">Reset</button>
            </div>
        </div>


        <div class="editor">
            <textarea name="inputbox" @keyup="(event) => colour(event.target.value)"></textarea>
            <div class="output" id="output">
            </div>
        </div>

        <div class="bgs">
            <div class="dirt"></div>
            <div class="plains"></div>
            <div class="item"></div>

        </div>
    </div>
</template>

