<script setup>
import { onMounted, ref } from 'vue';
import { Defaults } from '../../../../defaults';

const props = defineProps(['colors']);

const filteredColors = ref(props.colors);

onMounted( () => {
    document.querySelectorAll('.btn-copy').forEach( (value, key) => {
        value.addEventListener('click', (element) => {
            let value = element.target.closest('.color__code').querySelector('.color__code-name').innerText;
            if(value != null) Defaults.copy(value);
        });
    } );
} );

</script>

<template>
    <div class="colors__list colors__list_2x">
        <div class="color" v-if="filteredColors.includes('black')">
            <div class="color__header"><span>Black</span></div>
            <div class="color__body color__body_black">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§0</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&0</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">black</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A70</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#000000</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#000000</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredColors.includes('dark gray')">
            <div class="color__header"><span>Dark Gray</span></div>
            <div class="color__body color__body_darkgray">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§8</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&8</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">dark_gray</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A78</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#555555</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#151515</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredColors.includes('gray')">
            <div class="color__header"><span>Gray</span></div>
            <div class="color__body color__body_gray">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§7</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&7</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">gray</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A77</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#AAAAAA</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#2A2A2A</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

            </div>
        </div>

        <div class="color" v-if="filteredColors.includes('white')">
            <div class="color__header"><span>White</span></div>
            <div class="color__body color__body_white">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§f</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&f</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">white</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A7f</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#FFFFFF</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#3F3F3F</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredColors.includes('gold')">
            <div class="color__header"><span>Gold</span></div>
            <div class="color__body color__body_gold">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§6</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&6</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">gold</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A76</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#FFAA00</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#2A2A00</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredColors.includes('minecoin gold')">
            <div class="color__header"><span>Minecoin Gold</span></div>
            <div class="color__body color__body_minecoingold">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§g</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&g</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">minecoin_gold</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A7g</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#DDD605</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#373501</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredColors.includes('yellow')">
            <div class="color__header"><span>Yellow</span></div>
            <div class="color__body color__body_yellow">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§e</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&e</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">yellow</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A7e</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#FFFF55</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#3F3F15</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredColors.includes('green')">
            <div class="color__header"><span>Green</span></div>
            <div class="color__body color__body_green">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§a</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&a</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">green</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A7a</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#55FF55</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#153F15</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

            </div>
        </div>

        <div class="color" v-if="filteredColors.includes('dark green')">
            <div class="color__header"><span>Dark Green</span></div>
            <div class="color__body color__body_darkgreen">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§2</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&2</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">dark_green</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A72</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#00AA00</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#002A00</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredColors.includes('light purple')">
            <div class="color__header"><span>Light Purple</span></div>
            <div class="color__body color__body_lightpurple">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§d</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&d</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">light_purple</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A7d</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#FF55FF</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#3F153F</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredColors.includes('dark purple')">
            <div class="color__header"><span>Dark Purple</span></div>
            <div class="color__body color__body_darkpurple">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§5</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&5</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">dark_purple</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A75</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#AA00AA</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name"> #2A002A</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredColors.includes('blue')">
            <div class="color__header"><span>Blue</span></div>
            <div class="color__body color__body_blue">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§9</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&9</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">blue</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A79</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#5555FF</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#15153F</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredColors.includes('dark blue')">
            <div class="color__header"><span>Dark Blue</span></div>
            <div class="color__body color__body_darkblue">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§1</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&1</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">dark_blue</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A71</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#0000AA</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#00002A</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredColors.includes('dark red')">
            <div class="color__header"><span>Dark Red</span></div>
            <div class="color__body color__body_darkred">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§4</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&4</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">dark_red</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A74</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#AA0000</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#2A0000</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredColors.includes('red')">
            <div class="color__header"><span>Red</span></div>
            <div class="color__body color__body_red">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§c</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&c</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">red</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A7c</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#FF5555</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#FF5555</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredColors.includes('aqua')">
            <div class="color__header"><span>Aqua</span></div>
            <div class="color__body color__body_aqua">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§b</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&b</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">aqua</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A7b</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#55FFFF</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#153F3F</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredColors.includes('dark aqua')">
            <div class="color__header"><span>Dark Aqua</span></div>
            <div class="color__body color__body_darkaqua">
                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">§3</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">&3</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">dark_aqua</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>

                <div class="color__codes">
                    <div class="color__code">
                        <span class="color__code-name">\u00A73</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#00AAAA</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                    <div class="color__code">
                        <span class="color__code-name">#002A2A</span>
                        <button class="btn-copy"><span>copy</span></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
