<script setup>
import { ref, reactive, watch } from 'vue';
import Footer from '../parts/Footer.vue';
import validation from '../../validation';

const passHidden = ref([true, true]);  // Is pass hidden?

const hasErrors = ref(false);  // Are errors set?
const errors = reactive({});   // List of errors
const registeredSuccessfully = ref(false);  // Was user registered successfully?

const fatalErrors = ref('');    // Fatal errors (From back)
const submitButtonDisabled = ref(false); // Is submit button disabled?

const _TRANSLATION = window._TRANSLATION;
const _BASE_LANG_URL = window._BASE_LANG_URL;

// Register
const register = () => {
    submitButtonDisabled.value = true;

    fatalErrors.value = '';
    if( Object.keys(errors).length == 0 ) hasErrors.value = false;

    let nickname = document.getElementById('nickname').value;
    let nicknameTest = validation.isLoginValid(nickname);
    if( !nicknameTest.valid ) {
        hasErrors.value = true;
        errors.nickname = nicknameTest.error;
    }

    let email = document.getElementById('email').value;
    let emailTest = validation.isEmailValid(email);
    if( !emailTest.valid ) {
        hasErrors.value = true;
        errors.email = emailTest.error;
    }

    let pass1 = document.getElementById('pass1').value;
    let pass1Test = validation.isPassValid(pass1);
    if( !pass1Test.valid ) {
        hasErrors.value = true;
        errors.password = pass1Test.error;
    }

    let pass2 = document.getElementById('pass2').value;
    let pass2Test = validation.isPassValid(pass2, 'pass2');
    if( !pass2Test.valid ) {
        hasErrors.value = true;
        errors.password_repeated = pass2Test.error;
    }

    if(pass1 !== pass2){
        hasErrors.value = true;
        errors.password = errors.password_repeated = window._VALIDATION.pass2.same;
    }

    let requirementsChecked = document.getElementById('requirements').checked;
    if(!requirementsChecked){
        hasErrors.value = true;
        errors.checkbox = true;
    }

    console.log(hasErrors.value);

    if(hasErrors.value) {
        submitButtonDisabled.value = false;
        return; // If errors are present, stop here
    }

    // Register Action
    axios.post('/register', {
        nickname, pass1, pass2, email
    })
    .then(function (response) {
        console.log(response);
        // Validation errors
        if(response.data.error != null){
            hasErrors.value = true;
            response.data.error.forEach( (value, index)  => {
                let errorType = Object.keys(value[0])[0];
                errors[errorType] = value[0][errorType];
            });
            submitButtonDisabled.value = false;
            return;
        }

        // Logical errors
        if(response.data.fatal_error != null){
            fatalErrors.value = response.data.fatal_error;
            submitButtonDisabled.value = false;
            return;
        }

        registeredSuccessfully.value = true;
    })
    .catch(function (error) {
        console.log(error);
        fatalErrors.value = error;
        submitButtonDisabled.value = false;
    });
}

/* Are passwords same? */
const arePasswordsSame = () => {
    let pass1 = document.getElementById('pass1').value;
    let pass2 = document.getElementById('pass2').value;

    if(pass2.length == 0) return;

    if(pass1 !== pass2){
        hasErrors.value = true;
        errors.password = errors.password_repeated = window._VALIDATION.pass2.same;
    } else {
        delete errors.password;
        delete errors.password_repeated;
    }
}

// Validate
const validate = (callback, type) => {
    let result = callback;
    if(!result.valid) {
        hasErrors.value = true;
        errors[type] = result.error;
    }
};

</script>

<template>
    <transition appear>
        <div class="wrapper-small" :class="registeredSuccessfully ? 'wrapper-small_flex' : ''">
            <a :href="_BASE_LANG_URL" class="link-clear link-logo">
                <img src="../../../images/logo.png" class="img-adaptive logo" alt="Logo">
            </a>

            <transition mode="out-in">
                <div class="box" v-if="!registeredSuccessfully">
                    <h1 class="title">{{ _TRANSLATION.register.title }}</h1>

                    <form class="form" id="reg-form" @keyup.enter="register">
                        <div class="input-group">
                            <label for="nickname">{{ _TRANSLATION.common.nickname }}</label>
                            <input type="text" class="input" :class="{'input_error': hasErrors && errors.nickname}" name="nickname" id="nickname" :placeholder="_TRANSLATION.common.nickname" @input="delete errors.nickname" :onFocusout="(event) => validate( validation.isLoginValid(event.target.value), 'nickname' )">
                            <transition>
                                <div class="input-group__error" v-if="hasErrors && errors.nickname">{{ errors.nickname }}</div>
                            </transition>
                        </div>

                        <div class="input-group">
                            <label for="email">{{ _TRANSLATION.common.email }}</label>
                            <input type="email" class="input" :class="{'input_error': hasErrors && errors.email}" name="email" id="email" :placeholder="_TRANSLATION.common.email" @input="delete errors.email" :onFocusout="(event) => validate( validation.isEmailValid(event.target.value), 'email' )">
                            <transition>
                                <div class="input-group__error" v-if="hasErrors && errors.email">{{ errors.email }}</div>
                            </transition>
                        </div>

                        <div class="input-group">
                            <label for="pass1">{{ _TRANSLATION.common.pass }}</label>
                            <div class="input-custom input-custom_full" :class="{'input_error': hasErrors && errors.password}">
                                <input :type="passHidden[0] ? 'password' : 'text'" class="input input_clear" name="pass1"
                                    id="pass1" :placeholder="_TRANSLATION.common.pass" @input="delete errors.password" :onFocusout="(event) => {  arePasswordsSame(); validate( validation.isPassValid(event.target.value), 'password' ) } ">
                                <div class="input-custom__manager" @click="passHidden[0] = !passHidden[0]">
                                    <transition>
                                        <div class="crossed-line" v-if="passHidden[0]"></div>
                                    </transition>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M8.33333 6.25H16.6667V8.33333H8.33333V6.25ZM4.16667 10.4167V8.33333H8.33333V10.4167H4.16667ZM2.08333 12.5V10.4167H4.16667V12.5H2.08333ZM2.08333 14.5833V12.5H0V14.5833H2.08333ZM4.16667 16.6667H2.08333V14.5833H4.16667V16.6667ZM8.33333 18.75H4.16667V16.6667H8.33333V18.75ZM16.6667 18.75V20.8333H8.33333V18.75H16.6667ZM20.8333 16.6667V18.75H16.6667V16.6667H20.8333ZM22.9167 14.5833V16.6667H20.8333V14.5833H22.9167ZM22.9167 12.5H25V14.5833H22.9167V12.5ZM20.8333 10.4167H22.9167V12.5H20.8333V10.4167ZM20.8333 10.4167V8.33333H16.6667V10.4167H20.8333ZM10.4167 11.4583H14.5833V15.625H10.4167V11.4583Z" fill="#8A8A8A"/>
                                    </svg>
                                </div>
                            </div>
                            <transition>
                                <div class="input-group__error" v-if="hasErrors && errors.password">{{ errors.password }}</div>
                            </transition>
                        </div>

                        <div class="input-group">
                            <label for="pass2">{{ _TRANSLATION.register.pass_repeated }}</label>
                            <div class="input-custom input-custom_full" :class="{'input_error': hasErrors && errors.password_repeated}">
                                <input :type="passHidden[1] ? 'password' : 'text'" class="input input_clear" name="pass2"
                                    id="pass2" :placeholder="_TRANSLATION.register.pass_repeated" @input="(event) => {delete errors.password_repeated}" :onFocusout="(event) => {  arePasswordsSame(); validate( validation.isPassValid(event.target.value, 'pass2'), 'password_repeated' ); }">

                                <div class="input-custom__manager" @click="passHidden[1] = !passHidden[1]">
                                    <transition>
                                        <div class="crossed-line" v-if="passHidden[1]"></div>
                                    </transition>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                                        <path d="M8.33333 6.25H16.6667V8.33333H8.33333V6.25ZM4.16667 10.4167V8.33333H8.33333V10.4167H4.16667ZM2.08333 12.5V10.4167H4.16667V12.5H2.08333ZM2.08333 14.5833V12.5H0V14.5833H2.08333ZM4.16667 16.6667H2.08333V14.5833H4.16667V16.6667ZM8.33333 18.75H4.16667V16.6667H8.33333V18.75ZM16.6667 18.75V20.8333H8.33333V18.75H16.6667ZM20.8333 16.6667V18.75H16.6667V16.6667H20.8333ZM22.9167 14.5833V16.6667H20.8333V14.5833H22.9167ZM22.9167 12.5H25V14.5833H22.9167V12.5ZM20.8333 10.4167H22.9167V12.5H20.8333V10.4167ZM20.8333 10.4167V8.33333H16.6667V10.4167H20.8333ZM10.4167 11.4583H14.5833V15.625H10.4167V11.4583Z" fill="#8A8A8A"/>
                                    </svg>
                                </div>
                            </div>
                            <transition>
                                <div class="input-group__error" v-if="hasErrors && errors.password_repeated">{{ errors.password_repeated }}</div>
                            </transition>
                        </div>

                        <label class="checkbox-container" :class="{'checkbox-container_error': hasErrors && errors.hasOwnProperty('checkbox')}"><p class="checkbox-moved">{{ _TRANSLATION.register.requirements }}</p>
                            <!-- <input type="checkbox" checked="checked"> -->
                            <input type="checkbox" id="requirements" name="requirements" @change="delete errors.checkbox">
                            <span class="checkmark"></span>
                        </label>

                        <div class="fatal-errors" v-if="fatalErrors.length > 0">
                            {{  fatalErrors  }}
                        </div>

                        <button class="btn" type="button" id="register" :disabled="submitButtonDisabled" @click="register"><span>{{ _TRANSLATION.register.create }}</span></button>

                        <div class="text-center">
                            <a :href="_BASE_LANG_URL + '/login'" class="link-small" id="login-link">{{ _TRANSLATION.register.sign_in }}</a>
                        </div>
                    </form>
                </div>
                <div class="box" v-else>
                    <h1 class="title">{{ _TRANSLATION.register.account_created }}</h1>

                    <form class="form">
                        <p class="reg-success">{{ _TRANSLATION.register.success }}</p>

                        <button class="btn" type="button" id="auth" onclick="window.open('/login', '_self')"><span>{{ _TRANSLATION.register.auth }}</span></button>
                    </form>
                </div>
            </transition>

            <!-- Footer Component -->
            <Footer></Footer>
        </div>
    </transition>
</template>
