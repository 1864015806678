<script setup>
import { ref } from 'vue';
import { Defaults } from '../../../../defaults';

const props = defineProps(['codes']);
const filteredCodes = ref(props.codes);

</script>

<template>
    <div class="table codes-table">
        <table cellspacing="0" cellpadding="0">
            <thead>
                <tr>
                    <td><span>Formatting</span></td>
                    <td><span>Code</span></td>
                    <td><span>Chat Code</span></td>
                    <td><span>Unicode</span></td>
                </tr>
            </thead>
            <tbody>
                <tr v-if="filteredCodes.includes('underline')">
                    <td><u>Underline</u></td>
                    <td>&u <button class="btn-copy" @click="Defaults.copy('&u')"><span>copy</span></button></td>
                    <td>§u <button class="btn-copy" @click="Defaults.copy('§u')"><span>copy</span></button></td>
                    <td>\u00A7u <button class="btn-copy" @click="Defaults.copy('\\u00A7u')"><span>copy</span></button></td>
                </tr>

                <tr v-if="filteredCodes.includes('bold')">
                    <td><b>Bold</b></td>
                    <td>&l <button class="btn-copy" @click="Defaults.copy('&l')"><span>copy</span></button></td>
                    <td>§l <button class="btn-copy" @click="Defaults.copy('§l')"><span>copy</span></button></td>
                    <td>\u00A7l <button class="btn-copy" @click="Defaults.copy('\\u00A7l')"><span>copy</span></button></td>
                </tr>
                <tr v-if="filteredCodes.includes('italic')">
                    <td><i>Italic</i></td>
                    <td>&o <button class="btn-copy" @click="Defaults.copy('&o')"><span>copy</span></button></td>
                    <td>§o <button class="btn-copy" @click="Defaults.copy('§o')"><span>copy</span></button></td>
                    <td>\u00A7o <button class="btn-copy" @click="Defaults.copy('\\u00A7o')"><span>copy</span></button></td>
                </tr>
                <tr v-if="filteredCodes.includes('strikethrough')">
                    <td><s>Strikethrough</s></td>
                    <td>&m <button class="btn-copy" @click="Defaults.copy('&m')"><span>copy</span></button></td>
                    <td>§m <button class="btn-copy" @click="Defaults.copy('§m')"><span>copy</span></button></td>
                    <td>\u00A7m <button class="btn-copy" @click="Defaults.copy('\\u00A7m')"><span>copy</span></button></td>
                </tr>
                <tr v-if="filteredCodes.includes('obfuscated')">
                    <td>Obfuscated</td>
                    <td>&k <button class="btn-copy" @click="Defaults.copy('&k')"><span>copy</span></button></td>
                    <td>§k <button class="btn-copy" @click="Defaults.copy('§k')"><span>copy</span></button></td>
                    <td>\u00A7k <button class="btn-copy" @click="Defaults.copy('\\u00A7k')"><span>copy</span></button></td>
                </tr>
                <tr v-if="filteredCodes.includes('reset')">
                    <td>Reset</td>
                    <td>&r <button class="btn-copy" @click="Defaults.copy('&r')"><span>copy</span></button></td>
                    <td>§r <button class="btn-copy" @click="Defaults.copy('§r')"><span>copy</span></button></td>
                    <td>\u00A7r <button class="btn-copy" @click="Defaults.copy('\\u00A7r')"><span>copy</span></button></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
