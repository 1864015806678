import.meta.glob([
    '../images/**',
]);

import './bootstrap';

// VUE 3 //
import { createApp } from 'vue';
import { createPinia } from 'pinia';

const app = createApp({});
const pinia = createPinia();
app.use(pinia);

// Global user store
import { useUserStore } from './stores/UserStore.js';
window.userStore = useUserStore();

/* Registration Component */
import Register from './components/register/Register.vue';
app.component('register-index', Register);

/* Login Component */
import Login from './components/login/Login.vue';
app.component('login-index', Login);

/* Reset Pass Component */
import PassReset from './components/pass_reset/PassReset.vue';
app.component('pass_reset-index', PassReset);

/* Dashbobard Component */
import Dashboard from './components/dashboard/Dashboard.vue';
app.component('dashboard', Dashboard);

/* Profile Component */
import Profile from './components/dashboard/profile/Profile.vue';
app.component('profile', Profile);

/* Color Code Component */
import Colors from './components/dashboard/colors/Colors.vue';
app.component('colors', Colors);

/* Terms Component */
import Terms from './components/dashboard/terms/Terms.vue';
app.component('terms', Terms);

/* Privacy Component */
import Privacy from './components/dashboard/privacy/Privacy.vue';
app.component('privacy', Privacy);

app.mount('#app');
// VUE 3 //
