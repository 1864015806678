<script setup>

import { onMounted, ref } from 'vue';
import { Defaults } from '../../../../defaults';

const props = defineProps(['colors']);

const filteredColors = ref(props.colors);

onMounted( () => {
    document.querySelectorAll('.code-copy').forEach( (value, key) => {
        value.addEventListener('click', (element) => {
            let value = element.target.parentElement.innerText;
            if(value != null) Defaults.copy(value);
        });
    } );
} );

</script>

<template>
    <div class="colors__table">
        <table cellspacing="0" cellpadding="0">
            <thead>
                <tr>
                    <td><span>Color</span></td>
                    <td><span>Chat Color</span></td>
                    <td><span>Code</span></td>
                    <td><span>Name</span></td>
                    <td><span>MODT Color</span></td>
                    <td><span>Color Code</span></td>
                </tr>
            </thead>
            <tbody>
                <tr v-if="filteredColors.includes('black')" class="row-black">
                    <td style="background: #000; color: #fff;">Black</td>
                    <td>§0 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&0 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>black <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A70 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#00000 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
                <tr v-if="filteredColors.includes('dark gray')" class="row-darkgray">
                    <td style="background: #555; color: #fff;">Dark Gray</td>
                    <td>§8 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&8 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>dark_gray <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A78 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#555555 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
                <tr v-if="filteredColors.includes('gray')" class="row-gray">
                    <td style="background: #aaa; color: #fff;">Gray</td>
                    <td>§7 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&7 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>gray <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A77 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#AAAAAA <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
                <tr v-if="filteredColors.includes('white')" class="row-white">
                    <td style="background: #fff; color: #181818;">White</td>
                    <td>§f <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&f <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>white <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A7f <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#FFFFFF <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
                <tr v-if="filteredColors.includes('gold')" class="row-gold">
                    <td style="background: #fa0; color: #fff;">Gold</td>
                    <td>§6 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&6 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>gold <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A76 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#FFAA00 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
                <tr v-if="filteredColors.includes('minecoin gold')" class="row-minecoingold">
                    <td style="background: #ddd605; color: #fff;">Minecoin Gold</td>
                    <td>§g <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&g <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>minecoin_gold <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A7g <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#DDD605 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
                <tr v-if="filteredColors.includes('yellow')" class="row-yellow">
                    <td style="background: #ff5; color: #181818;">Yellow</td>
                    <td>§e <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&e <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>yellow <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A7e <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#FFFF55 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
                <tr v-if="filteredColors.includes('green')" class="row-green">
                    <td style="background: #5f5; color: #181818;">Green</td>
                    <td>§a <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&a <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>green <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A7a <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#55FF55 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
                <tr v-if="filteredColors.includes('dark green')" class="row-darkgreen">
                    <td style="background: #0a0; color: #fff;">Dark Green</td>
                    <td>§2 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&2 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>dark_green <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A72 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#00AA00 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
                <tr v-if="filteredColors.includes('light purple')" class="row-lightpurple">
                    <td style="background: #f5f; color: #fff;">Light Purple</td>
                    <td>§d <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&d <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>light_purple <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A7d <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#ff55ff <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
                <tr v-if="filteredColors.includes('dark purple')" class="row-darkpurple">
                    <td style="background: #a0a; color: #fff;">Dark Purple</td>
                    <td>§5 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&5 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>dark_purple <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A75 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#AA00AA <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
                <tr v-if="filteredColors.includes('blue')" class="row-blue">
                    <td style="background: #55f; color: #fff;">Blue</td>
                    <td>§9 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&9 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>blue <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A79 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#5555FF <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
                <tr v-if="filteredColors.includes('dark blue')" class="row-darkblue">
                    <td style="background: #00a; color: #fff;">Dark Blue</td>
                    <td>§1 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&1 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>dark_blue <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A71 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#0000AA <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
                <tr v-if="filteredColors.includes('dark red')" class="row-darkred">
                    <td style="background: #a00; color: #fff;">Dark Red</td>
                    <td>§4 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&4 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>dark_red <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A74 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#AA0000 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
                <tr v-if="filteredColors.includes('red')" class="row-red">
                    <td style="background: #f55; color: #fff;">Red</td>
                    <td>§o <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&o <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>red <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A7o <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#FF5555 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
                <tr v-if="filteredColors.includes('aqua')" class="row-aqua">
                    <td style="background: #5ff; color: #181818;">Aqua</td>
                    <td>§b <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&b <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>aqua <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A7b <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#55FFFF <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
                <tr v-if="filteredColors.includes('dark aqua')" class="row-darkaqua">
                    <td style="background: #0aa; color: #181818;">Dark Aqua</td>
                    <td>§3 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>&3 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>dark_aqua <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>\u00A73 <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                    <td>#00AAAA <img src="../../../../../images/icon-copy.png" alt="" class="code-copy"></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
