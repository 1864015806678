<script setup>
import Banner from '../Banner.vue';

const _TRANSLATION = window._TRANSLATION;

</script>

<template>
    <Banner :title="_TRANSLATION.color_code.not_found_title" :extra_class="'banner_tall'">
        <template #subtitle>
            <h6 class="h6-title">
                {{ _TRANSLATION.color_code.not_found_label }}
            </h6>
        </template>
    </Banner>
</template>
