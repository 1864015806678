<script setup>
import { ref } from 'vue';
import { Defaults } from '../../../../defaults';

const props = defineProps(['codes']);
const filteredCodes = ref(props.codes);

</script>

<template>
    <div class="colors__list colors__list_codes">
        <div class="color" v-if="filteredCodes.includes('underline')">
            <div class="color__header"><span><u>Underline</u></span></div>
            <div class="color__body color__body_darkgray">
                <div class="color__code">
                    <span class="color__code-name">&u</span>
                    <button class="btn-copy" @click="Defaults.copy('&u')"><span>copy</span></button>
                </div>
                <div class="color__code">
                    <span class="color__code-name">§u</span>
                    <button class="btn-copy" @click="Defaults.copy('§u')"><span>copy</span></button>
                </div>
                <div class="color__code">
                    <span class="color__code-name">\u00A7u</span>
                    <button class="btn-copy" @click="Defaults.copy('\\u00A7u')"><span>copy</span></button>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredCodes.includes('bold')">
            <div class="color__header"><span><b>Bold</b></span></div>
            <div class="color__body color__body_darkgray">
                <div class="color__code">
                    <span class="color__code-name">&l</span>
                    <button class="btn-copy" @click="Defaults.copy('&l')"><span>copy</span></button>
                </div>
                <div class="color__code">
                    <span class="color__code-name">§l</span>
                    <button class="btn-copy" @click="Defaults.copy('§l')"><span>copy</span></button>
                </div>
                <div class="color__code">
                    <span class="color__code-name">\u00A7l</span>
                    <button class="btn-copy" @click="Defaults.copy('\\u00A7l')"><span>copy</span></button>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredCodes.includes('italic')">
            <div class="color__header"><span><i>Italic</i></span></div>
            <div class="color__body color__body_darkgray">
                <div class="color__code">
                    <span class="color__code-name">&o</span>
                    <button class="btn-copy" @click="Defaults.copy('&o')"><span>copy</span></button>
                </div>
                <div class="color__code">
                    <span class="color__code-name">§o</span>
                    <button class="btn-copy" @click="Defaults.copy('§o')"><span>copy</span></button>
                </div>
                <div class="color__code">
                    <span class="color__code-name">\u00A7o</span>
                    <button class="btn-copy" @click="Defaults.copy('\\u00A7o')"><span>copy</span></button>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredCodes.includes('strikethrough')">
            <div class="color__header"><span><s>Strikethrough</s></span></div>
            <div class="color__body color__body_darkgray">
                <div class="color__code">
                    <span class="color__code-name">&m</span>
                    <button class="btn-copy" @click="Defaults.copy('&m')"><span>copy</span></button>
                </div>
                <div class="color__code">
                    <span class="color__code-name">§m</span>
                    <button class="btn-copy" @click="Defaults.copy('§m')"><span>copy</span></button>
                </div>
                <div class="color__code">
                    <span class="color__code-name">\u00A7m</span>
                    <button class="btn-copy" @click="Defaults.copy('\\u00A7m')"><span>copy</span></button>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredCodes.includes('obfuscated')">
            <div class="color__header"><span>Obfuscated</span></div>
            <div class="color__body color__body_darkgray">
                <div class="color__code">
                    <span class="color__code-name">&k</span>
                    <button class="btn-copy" @click="Defaults.copy('&k')"><span>copy</span></button>
                </div>
                <div class="color__code">
                    <span class="color__code-name">§k</span>
                    <button class="btn-copy" @click="Defaults.copy('§k')"><span>copy</span></button>
                </div>
                <div class="color__code">
                    <span class="color__code-name">\u00A7k</span>
                    <button class="btn-copy" @click="Defaults.copy('\\u00A7k')"><span>copy</span></button>
                </div>
            </div>
        </div>

        <div class="color" v-if="filteredCodes.includes('reset')">
            <div class="color__header"><span>Reset</span></div>
            <div class="color__body color__body_darkgray">
                <div class="color__code">
                    <span class="color__code-name">&r</span>
                    <button class="btn-copy" @click="Defaults.copy('&r')"><span>copy</span></button>
                </div>
                <div class="color__code">
                    <span class="color__code-name">§r</span>
                    <button class="btn-copy" @click="Defaults.copy('§r')"><span>copy</span></button>
                </div>
                <div class="color__code">
                    <span class="color__code-name">\u00A7r</span>
                    <button class="btn-copy" @click="Defaults.copy('\\u00A7r')"><span>copy</span></button>
                </div>
            </div>
        </div>
    </div>
</template>
