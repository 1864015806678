<script setup>

import { ref, reactive } from 'vue';

import Leftbar from '../Leftbar.vue';
import Header from '../Header.vue';
import Banner from '../Banner.vue';
import validation from '../../../validation';

const props = defineProps(['user']);


window.userStore.setUser(props.user);

const userStore = window.userStore;

const passHidden = ref([true, true]);  // Is pass hidden?

const hasErrors = ref(false);  // Are errors set?
const errors = reactive({});   // List of errors

const nickChanged = ref(false); // Is nick changed?
const passwordChanged = ref(false); // Is password changed?
const emailSent = ref(false); // Was email sent?
const emailButtonClicked = ref(false); // Was email button clicked?
const avatarChanged = ref(false);   // Is avatar changed?

const hasDefaultAvatar = ref( userStore.user.avatar.includes('/default_avatars/') );

const _TRANSLATION = window._TRANSLATION;

/** Change nickname */
const changeNickname = () => {
    if( Object.keys(errors).length == 0 ) hasErrors.value = false;

    let nickname = document.getElementById('nickname').value;
    let nicknameTest = validation.isLoginValid(nickname);
    if( !nicknameTest.valid ) {
        hasErrors.value = true;
        errors.nickname = nicknameTest.error;
    }

    console.log(hasErrors.value);
    if(hasErrors.value) return; // If errors are present, stop here

    axios.post('/profile/change/nick', { nickname })
        .then(function (response) {
            console.log(response);
            // Validation errors
            if(response.data.error != null){
                hasErrors.value = true;
                validation.setErrors(response.data.error, errors);
                console.log(errors);
                return;
            }

            nickChanged.value = response.data.success;
        })
        .catch(function (error) {
            console.log(error);
        });
};

/** Change pass */
const changePass = () => {
    hasErrors.value = false;

    let password = document.getElementById('password').value;
    let passTest = validation.isPassValid(password);
    if( !passTest.valid ) {
        hasErrors.value = true;
        errors.password = passTest.error;
    }

    let password_new = document.getElementById('password_new').value;
    let passNewTest = validation.isPassValid(password);
    if( !passNewTest.valid ) {
        hasErrors.value = true;
        errors.password_new = passNewTest.error;
    }

    let password_new_repeated = document.getElementById('password_new_repeated').value;
    let passNewRepeatedTest = validation.isPassValid(password, 'pass2');
    if( !passNewRepeatedTest.valid ) {
        hasErrors.value = true;
        errors.password_new_repeated = passNewRepeatedTest.error;
    }

    if(password_new !== password_new_repeated){
        hasErrors.value = true;
        errors.password_new = errors.password_new_repeated = window._VALIDATION.pass2.same;
    }

    console.log(hasErrors.value);
    if(hasErrors.value) return; // If errors are present, stop here

    axios.post('/profile/change/password', { password, password_new, password_new_repeated })
        .then(function (response) {
            console.log(response);
            // Validation errors
            if(response.data.error != null){
                hasErrors.value = true;
                validation.setErrors(response.data.error, errors);
                console.log(errors);
                return;
            }

            passwordChanged.value = response.data.success;
        })
        .catch(function (error) {
            console.log(error);
        });
}

/** Confirm email */
const confirmEmail = () => {
    hasErrors.value = false;

    let email = document.getElementById('email').value;
    let emailTest = validation.isEmailValid(email);
    if( !emailTest.valid ) {
        hasErrors.value = true;
        errors.email = emailTest.error;
    }

    console.log(hasErrors.value);
    if(hasErrors.value) return; // If errors are present, stop here

    emailButtonClicked.value = true;

    axios.post('/profile/confirm/email', { email })
        .then(function (response) {
            console.log(response);
            // Validation errors
            if(response.data.error != null){
                hasErrors.value = true;
                validation.setErrors(response.data.error, errors);
                console.log(errors);
                return;
            }

            emailSent.value = true;
        })
        .catch(function (error) {
            emailButtonClicked.value = false;
            console.log(error);
        });
}

/** Upload avatar */
const uploadAvatar = (event) => {
    delete errors.avatar;

    let file = event.target.files[0];
    if(file){
        let formData = new FormData();
        formData.append('avatar', file);

        axios.post(`${window._BASE_LANG_URL}/profile/change/avatar`,
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
            ).then(function(response){
                if(response.data.error != null){
                    hasErrors.value = true;
                    validation.setErrors(response.data.error, errors);
                    console.log(errors);
                    return;
                }

                console.log(errors);

                avatarChanged.value = response.data.success;
                userStore.user.avatar = response.data.avatar;
                hasDefaultAvatar.value = false;
            })
            .catch(function (error) {
                console.log(error);
            });
    }
}

/** Delete avatar */
const deleteAvatar = () => {
    delete errors.avatar;

    axios.post(`${window._BASE_LANG_URL}/profile/delete/avatar`, {})
        .then(function(response){
            if(response.data.error != null){
                hasErrors.value = true;
                validation.setErrors(response.data.error, errors);
                console.log(errors);
                return;
            }

            console.log(errors);

            avatarChanged.value = response.data.success;
            userStore.user.avatar = response.data.avatar;

            hasDefaultAvatar.value = true;
        })
        .catch(function (error) {
            console.log(error);
        });
}

// Validate
const validate = (callback, type) => {
    let result = callback;
    if(!result.valid) {
        hasErrors.value = true;
        errors[type] = result.error;
    }
};

/* Are passwords same? */
const arePasswordsSame = () => {
    let pass1 = document.getElementById('password_new').value;
    let pass2 = document.getElementById('password_new_repeated').value;

    if(pass1 !== pass2){
        hasErrors.value = true;
        errors.password_new = errors.password_new_repeated = window._VALIDATION.pass2.same;
    } else {
        delete errors.password_new;
        delete errors.password_new_repeated;
    }
}

</script>

<template>
    <div class="wrapper">
        <Leftbar></Leftbar>
        <main class="main">
            <Header></Header>
            <!-- Main content -->
            <div class="main__content">
                <Banner :title="_TRANSLATION.profile.title"></Banner>

                <div class="card">
                    <div class="card__content card__content_flex">
                        <div class="card__content-left profile-settings-left">
                            <p class="card__title">{{ _TRANSLATION.profile.change_photo_title }}</p>
                            <div class="your-avatar">
                                <!-- <img src="../../../../images/avatar_big.png" alt="" class="card__avatar"> -->
                                <img :src="userStore.user.avatar" alt="" class="card__avatar">
                                <div class="your-avatar__actions">
                                    <button class="button" @click="$refs.avatar_input.click"><span>{{ _TRANSLATION.profile.btn_upload_photo }}</span></button>
                                    <p class="your-avatar__actions-label">{{ _TRANSLATION.profile.max_size }}</p>
                                    <button class="button" @click="deleteAvatar" :disabled="hasDefaultAvatar"><span>{{ _TRANSLATION.profile.btn_delete_photo }}</span></button>
                                </div>
                            </div>

                            <transition>
                                <div class="input-group__error" style="margin-top: 4px;" v-if="hasErrors && errors.avatar">{{ errors.avatar }}</div>
                                <p class="note green" v-else-if="avatarChanged">
                                    {{ avatarChanged }}
                                </p>
                            </transition>

                            <input ref="avatar_input" type="file" id="avatar" @change="uploadAvatar($event)" @click="$event.target.value = null" style="visibility: hidden; opacity: 0; height: 0; position: absolute;" >

                            <div class="change-nickname">
                                <p class="card__title">{{ _TRANSLATION.profile.change_nickname_title }}</p>
                                <div class="flex-group">
                                    <input type="text" class="input input_fullwidth" :class="{'input_error': hasErrors && errors.nickname}" @input="delete errors.nickname" :onFocusout="(event) => validate( validation.isLoginValid(event.target.value), 'nickname' )" name="nickname" id="nickname" :placeholder="_TRANSLATION.profile.enter_nickname"
                                        v-model="userStore.user.name">
                                    <button class="btn" style="width: 32px; height: 32px;" @click="changeNickname"><span><img
                                                src="../../../../images/ok_mine.svg" alt="" class="ok-icon"></span></button>
                                </div>
                                <transition>
                                    <div class="input-group__error" style="margin-top: 4px;" v-if="hasErrors && errors.nickname">{{ errors.nickname }}</div>
                                    <p class="note green" v-else-if="nickChanged">
                                        {{ nickChanged }}
                                    </p>
                                </transition>
                            </div>
                        </div>

                        <div class="card__content-right email-settings">
                            <p class="card__title">{{ _TRANSLATION.profile.your_email }}</p>
                            <input type="email" class="input input_fullwidth" name="email" id="email" :disabled="userStore.user.email_verified_at != null"
                                :class="[{ 'yellow': userStore.user.email_verified_at == null, 'input_error': hasErrors && errors.email }]" :placeholder="_TRANSLATION.profile.your_email"
                                v-model="userStore.user.email" @input="delete errors.email" :onFocusout="(event) => validate( validation.isEmailValid(event.target.value), 'email' )">
                            <transition>
                                <div class="input-group__error" style="margin-top: 4px;" v-if="hasErrors && errors.email">{{ errors.email }}</div>
                            </transition>
                            <p class="note yellow email-note" v-if="userStore.user.email_verified_at == null && !emailSent">{{ _TRANSLATION.profile.need_confirmation }}</p>
                            <p class="note green email-note" v-else-if="emailSent">{{ _TRANSLATION.profile.email_sent }}</p>
                            <p class="note green email-note" v-else>{{ _TRANSLATION.profile.account_confirmed }}</p>
                            <button class="button" @click="confirmEmail" :disabled="userStore.user.email_verified_at != null || emailSent || emailButtonClicked"><span>{{ _TRANSLATION.profile.btn_email_confirm }}</span></button>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card__content card__content_flex">
                        <div class="card__content-left">
                            <p class="card__title">{{ _TRANSLATION.profile.security_title }}</p>
                            <p class="note green">{{ _TRANSLATION.profile.security_label }}</p>

                            <div class="change-pass">

                                <div class="input-group">
                                    <label for="password">{{ _TRANSLATION.profile.current_password }}</label>
                                    <div class="input-custom input-custom_full"
                                        :class="{ 'input_error': hasErrors && errors.password }">
                                        <input type="password" class="input input_clear"
                                            name="password" id="password" :placeholder=" _TRANSLATION.profile.enter_password" @input="delete errors.password" :onFocusout="(event) => validate( validation.isPassValid(event.target.value), 'password' )">
                                    </div>
                                    <transition>
                                        <div class="input-group__error" v-if="hasErrors && errors.password">{{
                                            errors.password }}</div>
                                    </transition>
                                </div>

                                <div class="input-group">
                                    <label for="password_new">{{ _TRANSLATION.profile.new_password }}</label>
                                    <div class="input-custom input-custom_full"
                                        :class="{ 'input_error': hasErrors && errors.password_new }">
                                        <input :type="passHidden[0] ? 'password' : 'text'" class="input input_clear"
                                            name="password_new" id="password_new" :placeholder="_TRANSLATION.profile.enter_password" @input="delete errors.password_new" :onFocusout="(event) => { arePasswordsSame(); validate( validation.isPassValid(event.target.value), 'password_new' ) } ">
                                        <div class="input-custom__manager" @click="passHidden[0] = !passHidden[0]">
                                            <transition>
                                                <div class="crossed-line" v-if="passHidden[0]"></div>
                                            </transition>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                viewBox="0 0 25 25" fill="none">
                                                <path
                                                    d="M8.33333 6.25H16.6667V8.33333H8.33333V6.25ZM4.16667 10.4167V8.33333H8.33333V10.4167H4.16667ZM2.08333 12.5V10.4167H4.16667V12.5H2.08333ZM2.08333 14.5833V12.5H0V14.5833H2.08333ZM4.16667 16.6667H2.08333V14.5833H4.16667V16.6667ZM8.33333 18.75H4.16667V16.6667H8.33333V18.75ZM16.6667 18.75V20.8333H8.33333V18.75H16.6667ZM20.8333 16.6667V18.75H16.6667V16.6667H20.8333ZM22.9167 14.5833V16.6667H20.8333V14.5833H22.9167ZM22.9167 12.5H25V14.5833H22.9167V12.5ZM20.8333 10.4167H22.9167V12.5H20.8333V10.4167ZM20.8333 10.4167V8.33333H16.6667V10.4167H20.8333ZM10.4167 11.4583H14.5833V15.625H10.4167V11.4583Z"
                                                    fill="#8A8A8A" />
                                            </svg>
                                        </div>
                                    </div>
                                    <transition>
                                        <div class="input-group__error" v-if="hasErrors && errors.password_new">{{
                                            errors.password_new }}</div>
                                    </transition>
                                </div>

                                <div class="input-group">
                                    <label for="password_new_repeated">{{ _TRANSLATION.profile.confirm_password }}</label>
                                    <div class="input-custom input-custom_full"
                                        :class="{ 'input_error': hasErrors && errors.password_new_repeated }">
                                        <input :type="passHidden[1] ? 'password' : 'text'" class="input input_clear"
                                            name="password_new_repeated" id="password_new_repeated" :placeholder="_TRANSLATION.profile.enter_password" @input="delete errors.password_new_repeated" :onFocusout="(event) => { arePasswordsSame(); validate( validation.isPassValid(event.target.value), 'password_new_repeated' ) } ">
                                        <div class="input-custom__manager" @click="passHidden[1] = !passHidden[1]">
                                            <transition>
                                                <div class="crossed-line" v-if="passHidden[1]"></div>
                                            </transition>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"
                                                viewBox="0 0 25 25" fill="none">
                                                <path
                                                    d="M8.33333 6.25H16.6667V8.33333H8.33333V6.25ZM4.16667 10.4167V8.33333H8.33333V10.4167H4.16667ZM2.08333 12.5V10.4167H4.16667V12.5H2.08333ZM2.08333 14.5833V12.5H0V14.5833H2.08333ZM4.16667 16.6667H2.08333V14.5833H4.16667V16.6667ZM8.33333 18.75H4.16667V16.6667H8.33333V18.75ZM16.6667 18.75V20.8333H8.33333V18.75H16.6667ZM20.8333 16.6667V18.75H16.6667V16.6667H20.8333ZM22.9167 14.5833V16.6667H20.8333V14.5833H22.9167ZM22.9167 12.5H25V14.5833H22.9167V12.5ZM20.8333 10.4167H22.9167V12.5H20.8333V10.4167ZM20.8333 10.4167V8.33333H16.6667V10.4167H20.8333ZM10.4167 11.4583H14.5833V15.625H10.4167V11.4583Z"
                                                    fill="#8A8A8A" />
                                            </svg>
                                        </div>
                                    </div>
                                    <transition>
                                        <div class="input-group__error" v-if="hasErrors && errors.password_new_repeated">{{
                                            errors.password_new_repeated }}</div>
                                    </transition>
                                </div>

                                <button class="button" style="margin-top: 8px;" @click="changePass"><span>{{ _TRANSLATION.profile.btn_change_password }}</span></button>
                                <transition>
                                    <p class="note green" v-if="passwordChanged">
                                        {{ passwordChanged }}
                                    </p>
                                </transition>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </main>
    </div>
</template>
