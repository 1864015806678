<script setup>
import { nextTick } from 'vue';
import DropdownMenu from 'v-dropdown-menu';
import 'v-dropdown-menu/css';

const userStore = window.userStore;

const _TRANSLATION = window._TRANSLATION;
const _LANG = window._LANG;
const _LANG_URLS = window._LANG_URLS;
const _BASE_LANG_URL = window._BASE_LANG_URL;

const screenWidth = window.screen.width;

nextTick(() => {
    /** Set selected lang */
    document.querySelector('#dropdown-lang .selected__value').innerHTML = document.querySelector(`#dropdown-lang [data-lang="${_LANG}"]`).innerHTML;
});

</script>

<template>
    <header class="header">
        <div class="header__management">
            <button disabled class="button button_large button_flex" id="content"><img src="../../../images/book_green.png" alt=""
                    class="button__icon"><span>{{ _TRANSLATION.header.buttons.content }}</span>
            </button>
            <button disabled v-if="userStore.user != null" class="button button_large button_flex" id="subscriptions"><img
                    src="../../../images/sign.png" alt="" class="button__icon"><span>{{ _TRANSLATION.header.buttons.subscriptions }}</span>
            </button>

            <div class="header__access">
                <div v-if="userStore.user == null" class="header__access-account header__access-account_quest">
                    <a :href="`${_BASE_LANG_URL}/register`" class="link-clear">
                        <button class="button button_large button_autowidth button_flex"
                            id="head-register"><span>{{ _TRANSLATION.header.buttons.register }}</span></button>
                    </a>
                    <a :href="`${_BASE_LANG_URL}/login`" class="link-clear">
                        <button class="button button_large button_autowidth button_flex"
                            id="head-auth"><span>{{ _TRANSLATION.header.buttons.auth }}</span></button>
                    </a>
                </div>

                <div v-else class="header__access-account header__access-account_user">
                    <!-- <img src="../../../images/avatar_demo.png" class="header-avatar" alt=""> -->
                    <a :href="`${_BASE_LANG_URL}/profile`" class="link-clear"><img :src="userStore.user.avatar" class="header-avatar" alt=""></a>
                    <!-- Account settings -->
                    <dropdown-menu class="dropdown dropdown_menu dropdown_wide" :overlay="false"
                        :direction="right" :dropup="false">
                        <template #trigger>
                            <div class="selected">
                                <div class="dropdown-arrow no-move">
                                </div>
                            </div>
                        </template>

                        <template #body>
                            <ul>
                                <li>
                                    <a :href="`${_BASE_LANG_URL}/profile`" class="link-clear">
                                        <span>{{ _TRANSLATION.header.buttons.profile }}</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="#" class="link-clear">
                                        <span>{{ _TRANSLATION.header.buttons.crafts }}</span>
                                    </a>
                                </li>
                            </ul>
                            <div class="border"></div>
                            <ul>
                                <li>
                                    <a :href="`${_BASE_LANG_URL}/logout`" class="link-clear link-flex">
                                        <img src="../../../images/exit.svg" alt="">
                                        <span>{{ _TRANSLATION.header.buttons.logout }}</span>
                                    </a>
                                </li>
                            </ul>
                        </template>

                    </dropdown-menu>
                </div>

                <dropdown-menu class="dropdown dropdown_langs dropdown_menu" id="dropdown-lang" :overlay="false"
                    direction="left" :dropup="false">
                    <template #trigger>
                        <div class="selected">
                            <div class="selected__value">
                                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="19" viewBox="0 0 26 19"
                                    fill="none">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V19H0V0Z" fill="#FFD700" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V9.5H0V0Z" fill="#0057B8" />
                                </svg>
                                <span>UA</span>
                            </div>
                            <div class="dropdown-arrow">
                            </div>
                        </div>
                    </template>

                    <template #body>
                        <ul>
                            <li>
                                <a :href="_LANG_URLS.en" class="link-clear" data-lang="en">
                                    <img src="../../../images/en.svg" alt="">
                                    <span>EN</span>
                                </a>
                            </li>
                            <li>
                                <a :href="_LANG_URLS.es" class="link-clear" data-lang="es">
                                    <img src="../../../images/es.svg" alt="">
                                    <span>ES</span>
                                </a>
                            </li>
                            <li>
                                <a :href="_LANG_URLS.de" class="link-clear" data-lang="de">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="19" viewBox="0 0 26 19"
                                        fill="none">
                                        <g clip-path="url(#clip0_326_3581)">
                                            <path d="M0 12.6666H26V19H0V12.6666Z" fill="#FFCE00" />
                                            <path d="M0 0H26V6.33333H0V0Z" fill="black" />
                                            <path d="M0 6.33337H26V12.6667H0V6.33337Z" fill="#DD0000" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_326_3581">
                                                <rect width="26" height="19" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span>DE</span>
                                </a>
                            </li>
                            <li>
                                <a :href="_LANG_URLS.fr" class="link-clear" data-lang="fr">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="20" viewBox="0 0 26 20"
                                        fill="none">
                                        <g clip-path="url(#clip0_326_3587)">
                                            <mask id="mask0_326_3587" style="mask-type:luminance" maskUnits="userSpaceOnUse"
                                                x="0" y="0" width="26" height="20">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V20H0V0Z"
                                                    fill="white" />
                                            </mask>
                                            <g mask="url(#mask0_326_3587)">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M17.875 0H26V20H17.875V0Z"
                                                    fill="#F50100" />
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H9.75V20H0V0Z"
                                                    fill="#2E42A5" />
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M8.125 0H17.875V20H8.125V0Z" fill="#F7FCFF" />
                                            </g>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_326_3587">
                                                <rect width="26" height="20" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <span>FR</span>
                                </a>
                            </li>
                            <li>
                                <a :href="_LANG_URLS.ua" class="link-clear" data-lang="ua">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="19" viewBox="0 0 26 19"
                                        fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V19H0V0Z" fill="#FFD700" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V9.5H0V0Z" fill="#0057B8" />
                                    </svg>
                                    <span>UA</span>
                                </a>
                            </li>
                            <li>
                                <a :href="_LANG_URLS.ru" class="link-clear" data-lang="ru">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="19" viewBox="0 0 26 19"
                                        fill="none">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H26V19H0V0Z" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 6.33337H26V19H0V6.33337Z"
                                            fill="#0039A6" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M0 12.6666H26V19H0V12.6666Z"
                                            fill="#D52B1E" />
                                    </svg>
                                    <span>RU</span>
                                </a>
                            </li>
                        </ul>
                    </template>

                </dropdown-menu>

            </div>
        </div>
    </header>
</template>
